body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f5f5dc;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

svg {
  display: block; /* Removes extra space below SVG */
  max-width: 100%; /* Ensures it doesn't overflow width */
  max-height: 100vh; /* Ensures it doesn't overflow height */
  margin: auto; /* Center the SVG if smaller than the viewport */
}

body{
  background: #3f4249;
  margin:0;
}
.coffee{ 
  width: 320px;
  height: 100%;
  margin: 0 auto 100px auto;
}
 
.coffee-bg-lid{
  position: relative;
  top:176px;
  left:2px;
  border-radius: 60%;
  background: #e6e6e6;
  width: 312px;
  height:35px;
  z-index: -5;
}
.coffee-rim,.coffee-rim-bt{
   position: relative;  
   border-radius: 50%;
   border-bottom: 3px solid #cdcdcd;
   z-index: 10;
}
.coffee-rim{
  top:200px;
  left:13px;
  width: 288px;
  height:25px;
}
.coffee-rim-bt{
  top:410px;
  left:70px;
  width: 175px;
  height: 65px;
}
.coffee-color{
  position: relative;
  top:50px;
  left:8px;
  border-radius: 50%;
  border-bottom: 4px solid #fff;
  border-top: 7px solid #c0a25a;
  background: #d6b971;
  width: 302px;
  height:20px;
  z-index: 5;
}

.coffee-cup{
  position: relative;
  top:25px;
  border-top: 360px solid  #e6e6e6; 
  border-left: 80px solid transparent;
  border-right: 80px solid transparent; 
  border-bottom: 16px solid #e6e6e6;
  height: 0;
  width: 155px;
  border-radius: 5% 5% 45% 45%;
  z-index: 4;
}

.coffee-shadow{
  position: relative;
  top:0;
  left:20px;
  background:#393c41; 
  height:20px;
  width: 275px;
  border-radius: 50%;
  box-shadow: -1px 2px 10px 8px #393c41;
}

.steam{
position: relative;
top:40px;
left:125px;
-webkit-animation: steam 5s linear infinite 3s;
-moz-animation: steam 5s linear infinite 3s;
-ms-animation: steam 5s linear infinite 3s;
-o-animation: steam 5s linear infinite 3s;
animation: steam 5s linear infinite 3s;
  
}
.steam:before,
.steam:after{
  content: " ";
  position: absolute;
  top:0;
  left:-15px;
  border-right: 0px solid transparent;
  border-top: 2px solid #d6d2d2;
  border-left: 5px solid #d6d2d2;
  border-bottom: 2px solid transparent;
  width: 40px;
  height: 70px;
  z-index: 4;
  border-radius: 80% 0 10% 10%; 

 -webkit-transform: rotate(168deg) skewY(10deg);
 -moz-transform: rotate(168deg) skewY(10deg);
 -ms-transform: rotate(168deg) skewY(10deg);
 -o-transform: rotate(168deg) skewY(10deg);
 transform: rotate(168deg) skewY(10deg);

 -webkit-filter: blur(7px);
 -moz-filter: blur(7px);
 -o-filter: blur(7px);
 -ms-filter: blur(7px);
  filter: blur(7px);
 }

.steam:after{
top:40px;
left:-35px;
z-index: 4;
border-radius: 80% 0 10% 10%; 
-webkit-transform: rotate(315deg) skewY(10deg);
-moz-transform: rotate(315deg) skewY(10deg);
-ms-transform: rotate(315deg) skewY(10deg);
-o-transform: rotate(315deg) skewY(10deg);
transform: rotate(315deg) skewY(10deg);

-webkit-filter: blur(7px);
-moz-filter: blur(7px);
-o-filter: blur(7px);
-ms-filter: blur(7px);
filter: blur(7px);
}


@-webkit-keyframes steam {
0% { top: 100px; opacity: .075; visibility: visible; }
20% { top: 80px; opacity: 0.2; }
40% { top: 60px; opacity: 0.4; }
60% { top: 40px; opacity: 0.2; }
80% { top: 20px; opacity: .075; }
100% { top: 0px; opacity: 0; visibility: hidden; }
}

@-moz-keyframes steam {
0% { top: 100px; opacity: .075; visibility: visible; }
20% { top: 80px; opacity: 0.2; }
40% { top: 60px; opacity: 0.4; }
60% { top: 40px; opacity: 0.2; }
80% { top: 20px; opacity: .075; }
100% { top: 0px; opacity: 0; visibility: hidden; }
}

@-ms-keyframes steam {
0% { top: 100px; opacity: .075; visibility: visible; }
20% { top: 80px; opacity: 0.2; }
40% { top: 60px; opacity: 0.4; }
60% { top: 40px; opacity: 0.2; }
80% { top: 20px; opacity: .075; }
100% { top: 0px; opacity: 0; visibility: hidden; }
}

@-o-keyframes steam {
0% { top: 100px; opacity: .075; visibility: visible; }
20% { top: 80px; opacity: 0.2; }
40% { top: 60px; opacity: 0.4; }
60% { top: 40px; opacity: 0.2; }
80% { top: 20px; opacity: .075; }
100% { top: 0px; opacity: 0; visibility: hidden; }
}

@keyframes steam {
0% { top: 100px; opacity: .075; visibility: visible; }
20% { top: 80px; opacity: 0.2; }
40% { top: 60px; opacity: 0.4; }
60% { top: 40px; opacity: 0.2; }
80% { top: 20px; opacity: .075; }
100% { top: 0px; opacity: 0; visibility: hidden; }
}